var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState, forwardRef, useContext, } from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import { useUniqueId } from '../../hooks/';
import { Card } from '../Card';
import { Heading3 } from '../Heading';
import { Icon } from '../Icon';
var AccordionHeader = styled.div(function (_a) {
    var _b = _a.theme, accordion = _b.accordion, card = _b.card, mediaQueries = _b.mediaQueries;
    return "\n    cursor: pointer;\n    padding: ".concat(accordion.header.paddingVertical[0], " ").concat(card.padding[0], ";\n\n    ").concat(mediaQueries.smallAndUp, " {\n      padding: ").concat(accordion.header.paddingVertical[1], " ").concat(card.padding[1], ";\n    }\n");
});
var AccordionButton = styled.button(function (_a) {
    var _b = _a.theme, focusRing = _b.focusRing, accordion = _b.accordion, card = _b.card;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    border: inherit;\n    background: inherit;\n    font-size: inherit;\n    line-height: inherit;\n    color: ", ";\n    text-align: left;\n    font-weight: inherit;\n    font-family: inherit;\n    display: flex;\n    border-radius: ", ";\n    padding: 0;\n    max-width: 100%;\n\n    &:focus {\n      ", ";\n    }\n  "], ["\n    border: inherit;\n    background: inherit;\n    font-size: inherit;\n    line-height: inherit;\n    color: ", ";\n    text-align: left;\n    font-weight: inherit;\n    font-family: inherit;\n    display: flex;\n    border-radius: ", ";\n    padding: 0;\n    max-width: 100%;\n\n    &:focus {\n      ", ";\n    }\n  "])), accordion.header.color, card.borderRadius, focusRing);
});
var AccordionContent = styled.div(function (_a) {
    var _b = _a.theme, card = _b.card, mediaQueries = _b.mediaQueries;
    return "\n    padding: ".concat(card.padding[0], ";\n    padding-top: 0;\n\n    ").concat(mediaQueries.smallAndUp, " {\n      padding: ").concat(card.padding[1], ";\n      padding-top: 0;\n    }\n   ");
});
var AccordionIcon = styled(Icon)(function (_a) {
    var accordion = _a.theme.accordion;
    return "\n    margin-right: ".concat(accordion.header.iconGap, ";\n    align-self: center;\n    flex-shrink: 0;\n  ");
});
export var AccordionWrapper = styled.div(function (_a) {
    var card = _a.theme.card;
    return "\n    border-top: ".concat(card.borderWidth, " solid ").concat(card.borderColor, ";\n    &:first-child {\n      border: none;\n    }\n  ");
});
export var Accordion = forwardRef(function (_a, ref) {
    var children = _a.children, title = _a.title, _b = _a.expanded, expanded = _b === void 0 ? false : _b, onToggle = _a.onToggle, _c = _a.headingComponent, Heading = _c === void 0 ? Heading3 : _c, rest = __rest(_a, ["children", "title", "expanded", "onToggle", "headingComponent"]);
    var id = useUniqueId();
    var titleId = "title-".concat(id);
    var contentId = "content-".concat(id);
    var accordion = useContext(ThemeContext).accordion;
    var _d = useState(expanded), isExpanded = _d[0], setIsExpanded = _d[1];
    return (React.createElement(AccordionWrapper, __assign({}, rest),
        React.createElement(AccordionHeader, { onClick: function () {
                setIsExpanded(!isExpanded);
                onToggle && onToggle(!isExpanded);
            } },
            React.createElement(Heading, null,
                React.createElement(AccordionButton, { type: "button", onClick: function () {
                        setIsExpanded(!isExpanded);
                        onToggle && onToggle(!isExpanded);
                    }, "aria-expanded": isExpanded, "aria-controls": id, id: titleId, ref: ref },
                    React.createElement(AccordionIcon, { name: isExpanded ? 'minus' : 'plus', size: accordion.header.iconSize }),
                    title))),
        React.createElement(AccordionContent, { id: id, "data-testid": contentId, role: "region", "aria-labelledby": titleId, hidden: !isExpanded }, children)));
});
export var AccordionGroup = styled(Card)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 0;\n"], ["\n  padding: 0;\n"])));
var templateObject_1, templateObject_2;
